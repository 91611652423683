<template>
  <div style="background-color: #f7f7f7">
    <div class="top_body">
      <div class="top_body_item" style="background-color: #fff">
        <div class="top_body_item_title">
          <h3 style="font-weight: bold">
            今日超时未辅导 <span style="color: #f6ad3c">{{ tableData.length }}</span>
          </h3>
          <div style="cursor: pointer; color: #489cfa" @click="Gendo" v-if="tableData.length">更多</div>
        </div>
        <el-table :data="tableData" style="width: 100%; margin: 14rem 0" :row-class-name="tableRowClassName">
          <el-table-column prop="teacher" label="教师姓名"></el-table-column>
          <el-table-column prop="subject" label="辅导学科"></el-table-column>
          <el-table-column prop="student" label="待辅导学生名单"></el-table-column>
          <el-table-column prop="plan_start_at" label="	计划开始辅导时间"></el-table-column>
          <el-table-column prop="plan_end_at" label="计划结束辅导时间"></el-table-column>
        </el-table>
      </div>
    </div>
    <div style="background-color: #fff; margin: 0 10rem; border-radius: 10rem">
      <div class="middle_body_item_serch">
        <el-date-picker
            v-model="middlesearch.day"
            style="margin-right: 10rem; flex: 1"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            size="small"
            :clearable="false"
            @enter="searchData"
        >
        </el-date-picker>
        <el-select v-model="middlesearch.school_id" placeholder="筛选校区" style="margin-right: 10rem; flex: 1" :disabled="!middlesearch.day" size="small" @enter="searchData">
          <el-option v-for="item in schoolArr" :key="item.id" :label="item.school_name" :value="item.id"></el-option>
        </el-select>
        <el-select v-model="middlesearch.fistSubject_id" placeholder="筛选辅导类型" style="margin-right: 10rem;flex: 1" :disabled="!middlesearch.school_id" size="small" @change="onFistSubject" @enter="searchData">
          <el-option v-for="item in fistSubjectArr" :key="item.id" :label="item.name" :value="item.value"></el-option>
        </el-select>
        <el-select v-model="middlesearch.subject_id" placeholder="筛选学科" style="flex: 1" :disabled="!middlesearch.fistSubject_id" size="small" @change="onsubject" @enter="searchData">
          <el-option v-for="item in subjectArr" :key="item.id" :label="item.subject_name" :value="item.id"></el-option>
        </el-select>
        <el-button type="primary" style="margin-left: 16rem" @click.prevent="searchData" size="small" :disabled="!middlesearch.subject_id">搜索</el-button>
        <el-button style="margin-left: 16rem" @click.prevent="reset" size="small">重置</el-button>
      </div>
      <div style="width: 100%;height: 450rem" v-if="echartsShow">
        <div style="display: flex;flex-wrap: wrap;padding: 0 24rem">
          <el-checkbox v-model="checkAll" @change="handleCheckAllChange" style="margin-right: 24rem">全选</el-checkbox>
          <el-checkbox-group v-model="checkedCities" v-for="(item,index) in cities" @change="handleCheckedCitiesChange" style="margin-right: 24rem;color: red !important;">
            <el-checkbox :label="index" :key="item">{{ item }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div id="main" style="width: 100%;height: 400rem;margin-top: 30rem" v-if="show"></div>
      </div>
      <div v-if="praise_list.length || bad_list.length">
        <div class="middle_body_title">
          <div v-for="(item, index) in 4" class="middle_body_title_item" :style="{ background: bgColorArr[index] }">
            <div class="middle_body_title_item_title">
              <div
                  style="width: 8rem; height: 8rem; border-radius: 50%; margin-right: 10rem"
                  :style="{ background: index < 2 ? '#91E0A1' : '#F76560' }"
              ></div>
              {{ stringArray[index].name }}
            </div>
            <div class="middle_body_title_item_box">
              <div class="middle_body_title_item_box_left">{{ stringArray[index].data }}</div>
              <!-- <div class="middle_body_title_item_box_rigth">
              <img :src="imgArr[index]" />
            </div> -->
            </div>
          </div>
        </div>
        <div class="middle_body_footer">
          <div class="middle_body_footer_left">
            <h3 style="margin-bottom: 24rem">好评榜</h3>
            <div style="width: 80%; background: #ffffff; opacity: 1; border-radius: 10rem; padding-right: 10%">
              <div class="middle_body_footer_left_title">
                <div v-for="(item, index) in rankingArr" class="middle_body_footer_left_title_div">
                  <span v-if="index == 2">好</span>{{ item }}
                </div>
              </div>
              <div
                  v-for="(item, index) in praise_list"
                  style="margin: 20rem; width: 100%; height: 40rem; background-size: 95%; background-repeat: no-repeat"
                  :style="{ backgroundImage: 'url(' + bgImgArr[index] + ')' }"
              >
                <div style="width: 100%; display: flex">
                  <div style="width: 10%" v-if="index < 3">
                    <img :src="rigthImg" alt=""/>
                  </div>
                  <div v-else style="padding-left: 10rem; width: 10%">{{ index + 1 }}</div>
                  <div
                      style="
											width: 70%;
											display: flex;
											align-items: center;
											font-size: 9rem;
											font-family: Microsoft YaHei;
											font-weight: 400;
											color: #333333;
										"
                      v-if="praise_list[index]"
                  >
                    {{ praise_list[index].name }}
                  </div>
                  <div
                      style="
											display: flex;
											align-items: center;
											font-size: 9rem;
											font-family: Microsoft YaHei;
											font-weight: 400;
											color: #333333;
										"
                      v-if="praise_list[index]"
                  >
                    {{ praise_list[index].percentage }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="middle_body_footer_rigth">
            <h3 style="margin-bottom: 24rem">差评榜</h3>
            <div></div>
            <div style="width: 80%; background: #ffffff; opacity: 1; border-radius: 10rem; padding-right: 10%">
              <div class="middle_body_footer_rigth_title">
                <div v-for="(item, index) in rankingArr" class="middle_body_footer_rigth_title_div">
                  <span v-if="index == 2">差</span>{{ item }}
                </div>
              </div>
              <div v-for="(item, index) in bad_list" style="margin: 20rem; width: 100%; height: 40rem">
                <div style="display: flex">
                  <div style="width: 10%">
                    <img :src="leftImg" alt="" v-if="index < 3"/>
                    <div v-else style="padding-left: 10rem">{{ index + 1 }}</div>
                  </div>
                  <div style="width: 70%;display: flex;align-items: center;font-size: 9rem;font-weight: 400;color: #333333; " v-if="bad_list[index]">
                    {{ bad_list[index].name }}
                  </div>
                  <div style=" display: flex; align-items: center; font-size: 9rem; font-weight: 400; color: #333333; " v-if="bad_list[index]">
                    {{ bad_list[index].percentage }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <t-result description="描述文字" v-else></t-result>
    </div>
    <el-dialog title="今日超时未辅导" :visible.sync="dialogVisible" class="ccccc" :before-close="handleClose">
      <list-template
          :total="total"
          :table-data="tableDataTo"
          @onChangePage="changeCurrentPage"
          :table-config="tableConfig"
      ></list-template>
      <span slot="footer">
				<el-button type="primary" @click="dialogVisible = false">关闭</el-button>
			</span>
    </el-dialog>
  </div>
</template>

<script>
import a from '../../../../assets/img/5期/1.png'
import b from '../../../../assets/img/5期/2.png'
import c from '../../../../assets/img/5期/3.png'
import d from '../../../../assets/img/5期/4.png'
import crown from '../../../../assets/img/5期/one.png'
import sub from '../../../../assets/img/5期/tow.png'
import quarter from '../../../../assets/img/5期/three.png'
import left from '../../../../assets/img/5期/left.png'
import rigth from '../../../../assets/img/5期/rigth.png'
import * as echarts from 'echarts';

export default {
	_config:{"route":{"path":"details","meta":{"title":"数据看板"}}},
  data() {
    return {
      showEcharts: false,
      leftImg: left,
      rigthImg: rigth,
      bgColorArr: ['#f0fbf3', '#ffeeeb', '#feeed8', '#f3f0fe'],
      rankingArr: ['排名', '姓名', '评率'],
      bgImgArr: [crown, sub, quarter],
      imgArr: [a, b, c, d],
      strArr: ['教师姓名', '辅导科目', '待辅导学生名单', '计划开始辅导时间', '计划结束辅导时间'],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 86400000
        }
      },
      dialogVisible: false,
      tableConfig: [
        {
          prop: 'teacher',
          label: '教师姓名'
        },
        {
          prop: 'subject',
          label: '辅导科目'
        },
        {
          prop: 'student',
          label: '待辅导学生名单'
        },
        {
          prop: 'plan_start_at',
          label: '计划开始辅导时间'
        },
        {
          prop: 'plan_end_at',
          label: '计划结束辅导时间'
        }
      ],
      search: null,
      tableData: [],
      tableDataTo: [],
      middlesearch: {},
      total: null,
      options: [],
      color: ['#ffff00', '#f2f2f2', '#f59a23'],
      page: 1,
      stringArray: [
        {name: '已辅导人数', data: null},
        {name: '已辅导次数', data: null},
        {name: '任务超时率', data: null},
        {name: '辅导差评率', data: null}
      ],
      schoolArr: [],
      subjectArr: [],
      fistSubjectArr: [{
        name: '学科',
        value: 1
      }, {
        name: '德育',
        value: 2
      }, {
        name: '心理',
        value: 3
      }],
      praise_list: [],
      bad_list: [],
      checkAll: true,
      checkedCities: [],
      cities: [],
      evaluate_score: [],
      evaluate_scoreArr: [],
      coach_date: [],
      show: true,
      echartsShow: false
    }
  },
  methods: {
    getData() {
      let {search, page} = this
      this.$_axios2.get('api/1v1/statistics/overtime-list', {params: {...search, page}}).then(res => {
        this.tableDataTo = res.data.data.data
        this.total = res.data.data.total
      })
    },
    Gendo() {
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    },
    changeCurrentPage(e) {
      this.page = e
      this.getData()
    },
    reset() {
      this.echartsShow = false
      // this.$_axios2.post('api/1v1/statistics/board', (this.middlesearch = {})).then(res => {
      this.middlesearch = {}
      this.praise_list = []
      this.bad_list = []
      this.stringArray[0].data = null
      this.stringArray[1].data = null
      this.stringArray[2].data = null
      this.stringArray[3].data = null
      // })
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 5) {
        return 'warning-row'
      }
    },
    searchData() {
      if (this.middlesearch.subject_id && this.middlesearch.subject_id !== '') {
        this.echartsShow = false
        this.show = false
        this.checkAll = true
        this.cities = []
        this.checkedCities = []
        this.$_axios2.get('api/1v1/statistics/board', {params: this.convertArrayValuesToString(this.middlesearch)}).then(res => {
          if (res.data.status === 0) {
            this.praise_list = res.data.data.praise_list
            this.bad_list = res.data.data.bad_list
            this.stringArray[0].data = res.data.data.people_num
            this.stringArray[1].data = res.data.data.total_num
            this.stringArray[2].data = res.data.data.timeout_rate
            this.stringArray[3].data = res.data.data.bad
          }
          this.show = true
          this.test()
        })
      }
    },
    handleCheckAllChange() {
      this.show = false
      if (this.checkedCities.length === this.cities.length) {
        this.checkedCities = []
        setTimeout(() => {
          this.show = true
          setTimeout(() => {
            this.testRangeData([])
          }, 100)
        }, 0)
      } else {
        this.checkedCities = this.cities.map((item, index) => index)
        setTimeout(() => {
          this.show = true
          setTimeout(() => {
            this.testRangeData(this.evaluate_scoreArr)
          }, 100)
        }, 0)
      }
    },
    onFistSubject(val) {
      this.middlesearch.subject_id = ''
      this.$_axios.get('site/fist-subject?type=' + val).then(res => {
        this.subjectArr = res.data.data
      })
    },
    onsubject() {
      this.$forceUpdate()
    },
    handleCheckedCitiesChange() {
      this.show = false
      this.checkAll = this.checkedCities.length === this.cities.length;
      setTimeout(() => {
        this.show = true
        setTimeout(() => {
          let data = []
          for (let i of this.checkedCities) {
            data.push(this.evaluate_scoreArr[i])
          }
          this.testRangeData(data)
        }, 100)
      }, 0)
    },
    testRangeData(data) {
      const chartDom = document.getElementById('main');
      const myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.coach_date
        },
        yAxis: {
          type: 'value',
        },
        series: data
      };
      setTimeout(() => {
        option && myChart.setOption(option);
      }, 10)
    },
    test() {
      this.$_axios2.get('api/1v1/statistics/chart', {params: this.convertArrayValuesToString(this.middlesearch)}).then(res => {
        if (res.data.status === 0) {
          if (res.data.data.coach_date.length !== 0) {
            if (res.data.data.evaluate_score !== 0) {
              this.echartsShow = true
              setTimeout(() => {
                this.coach_date = res.data.data.coach_date
                this.evaluate_scoreArr = this.evaluate_score = res.data.data.evaluate_score
                res.data.data.evaluate_score.forEach((item, index) => {
                  this.cities.push(item.name)
                  this.checkedCities.push(index)
                })
                this.testRangeData(this.evaluate_scoreArr)
              }, 0)
            } else this.echartsShow = false
          } else {
            this.echartsShow = false
          }
        }
      })
    },

    convertArrayValuesToString(obj, str = ',') {
      const newObj = {...obj};
      for (let key in newObj) {
        if (Array.isArray(newObj[key])) {
          newObj[key] = newObj[key].join(str);
        }
      }
      return newObj;
    }
  },
  created() {
    this.getData()
    this.$_axios.get('site/school').then(res => {
      this.schoolArr = res.data.data
    })
    this.$_axios2.get('api/1v1/statistics/overtime').then(res => {
      this.tableData = res.data.data
    })
  }
}
</script>

<style scoped lang="scss">
.top_body {
  display: flex;
  //padding-top: 20rem;
  .top_body_item {
    flex: 1;
    margin: 0 10rem;
    padding: 20rem;
    background-color: #fff;
    border-radius: 10rem;

    .top_body_item_title {
      display: flex;
      justify-content: space-between;
    }
  }
}

.middle_body_item_serch {
  margin: 10rem 10rem;
  display: flex;
  padding: 20rem 10rem;
  background: #ffffff;
  opacity: 1;
  border-radius: 10rem;
}

::v-deep .el-dialog {
  width: 1200rem !important;
  border-radius: 10rem !important;
}

.str_title {
  display: flex;
  justify-content: space-between;
  font-size: 8rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  letter-spacing: 7.5rem;
  opacity: 1;
  padding: 20rem 0;
}

.str_body {
  justify-content: space-between;
  height: 44rem;
  background: #ffffff;
  opacity: 1;
  margin-bottom: 10rem;
}

.str_body:nth-child(3) {
  border-radius: 10rem 10rem 2rem 2rem !important;
}

.str_body:last-child {
  border-radius: 2rem 2rem 10rem 10rem !important;
}

.table_item {
  display: flex;
  justify-content: space-between;
}

::v-deep .has-gutter {
  background-color: #f7f7f7 !important;
  //font-size: 8rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  margin-bottom: 10rem;
}

.el-table .success-row {
  background: #fff;
}

.middle_body_title {
  margin: 10rem;
  display: flex;
  padding: 20rem 10rem;
  opacity: 1;
  border-radius: 10rem;
  justify-content: space-between;

  .middle_body_title_item {
    width: 23%;
    height: 130rem;
    background: red;
    border-radius: 10rem;
    padding: 30rem;

    .middle_body_title_item_title {
      font-size: 8rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      display: flex;
      align-items: center;
    }

    .middle_body_title_item_box {
      width: 100%;
      height: 60rem;
      margin-top: 10rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .middle_body_title_item_box_left {
        font-size: 30rem;
        font-family: Microsoft YaHei;
        font-weight: 600;
      }

      .middle_body_title_item_box_rigth {
        width: 40rem;
        height: 40rem;
        background: #fffae8;
        border-radius: 50%;
        text-align: center;
        line-height: 40rem;
      }
    }
  }
}

.middle_body_footer {
  margin: 20rem 30rem;
  display: flex;

  .middle_body_footer_left {
    width: 48%;

    .middle_body_footer_left_title {
      display: flex;
      padding: 20rem;
      font-size: 7rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;

      .middle_body_footer_left_title_div {
        width: 40rem;
      }

      .middle_body_footer_left_title_div:nth-child(2) {
        width: 75%;
      }
    }
  }

  .middle_body_footer_rigth {
    margin-left: 4%;
    width: 48%;

    .middle_body_footer_rigth_title {
      display: flex;
      padding: 20rem;
      font-size: 7rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;

      .middle_body_footer_rigth_title_div {
        width: 40rem;
      }

      .middle_body_footer_rigth_title_div:nth-child(2) {
        width: 75%;
      }
    }
  }
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #000000 !important;
}

::v-deep .el-table__cell {
  font-weight: 300;
}
</style>
